@import '~antd/dist/antd.css';

.App {
	text-align: center;
}

.tooltip-inner {
	max-width: 370px;
	padding: 3px 8px;
	color: #fff;
	text-align: center;
	background-color: #000;
	border-radius: 4px;
}

@media only screen and (max-width: 1200px) {
	input.test-search {
		width: 450px;
	}

	div.search-row {
		width: 450px
	}
}

@media only screen and (min-width: 1520px) {
	input.test-search {
		width: 400px;
	}
	div.search-row {
		width: 560px
	}
}

@media only screen and (min-width: 1710px) {
	input.test-search {
		width: 450px;
	}
	div.search-row {
		width: 700px
	}
}

table.Items tbody tr td {
	line-height: 2.2em;
	border-right-width: 1px;
	border-color: white;
	cursor: default;
}

.beautiful-link {
	color: "#A2C7E2";
	cursor: pointer;
}

table.Items thead tr th {
	line-height: 2.2em;
	text-align: center;
	background-color: #cacacc;
	border-right-width: 1px;
	border-color: white;
	vertical-align: middle;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
	font-size: inherit;
	left: 10px;
	z-index: 2;
	color: black;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
	font-size: inherit;
	right: 10px;
	z-index: 2;
	color: black;
}

a {
	cursor: pointer;
}

.Item-material {
	cursor: pointer;
	word-break: break-word;
	white-space: normal;
}

.Item-part-number {
	text-align: center;
	word-break: break-all;
}

.stock-level button{
	cursor: pointer;
	color: white;
	text-decoration: none !important;
	border: 1px solid #0288D1;
	border-radius: .25em;
	padding: .1em .2em;
	word-break: keep-all;
	min-width: 50px;
	background-color: #0288D1;
}

.Attr {
	border: 1px solid #959595;
	border-radius: .25em;
	padding: .1em .3em;
	margin: .1em .3em .1em 0;
	line-height: 1.5em;
	cursor: default;
	display: inline-block;
}

.Attr-matched {
	cursor: pointer;
}

.Attr-exact {
	background-color: #ccffcc;
}

.Attr-positive {
	background-color: #ffffaa;
}

.Attr-negative {
	background-color: #ffaeae;
}

.App-logo{
	background-color: #00205C;
	border-radius: 0.25em;
	background-size: contain;
	background-repeat: no-repeat;
	background-origin: content-box;
	height: 40px;
	padding-left: 30px;
	margin-top: 8px;
}
.App-query {
	background-color: #00205C;
	background-size: contain;
	background-repeat: no-repeat;
	background-origin: content-box;
	border-top: 1px solid #00205C;
	border-bottom: 1px solid #00205C;
	border-radius: .75em;
	width: 70%;
	height: 55px;
	padding: 1px 12px;
	margin-bottom: 10px;
}

.App-query input {
	margin-top: 15px;
}
.App-query button {
	margin-top: 15px;
}

.App-query img {
	margin-top: 21px;
}

.App-query span {
	font-family: "Courier New";
	font-size: 1.5em;
	color: white;
}

.App-info {
	font-color: #333f4f;
	margin-top: 10px;
	margin-bottom: 10px;
}

.App-info span {
	padding: 20px;
}

.App-root {
	max-width: 1200px;
	padding: 10px;
}

.tooltip.top .tooltip-inner {
	background-color: #333f4f;
}

#requestText {
	width: 100%;
	height: 330px;
	margin-top: 10px;
}

.Contact-form textarea.form-control {
	height: 180px;
}

.form-group.required .control-label:after {
	content: " *";
	color: red;
}
.center{
	margin-left: 14%;
}
.lbl {
	margin-left: 18px;
	margin-right: 10px;
}
.MuiMenu-paper {
	max-height: calc(100% - 96px);
	margin-top: 65px;
	-webkit-overflow-scrolling: touch;
}
.col-md-1 {
	min-height: 1px;
	text-align: center;
	padding-right: 0;
	padding-left: 0;
}
.link {
	margin-left: 40%;
}
.hub-name {
	font-size: 15px;
	text-align: center;
	position: relative;
}


.heading {
	position: static;
	margin: 0;
	padding-top: 6px;
	padding-bottom: 6px;
	color: black;
	font-size: 12px;
	max-height: 38px;
}

.hub-name {
	font-size: 15px;
	text-align: center;
	position: relative;
}

.supplier-copy {
	display: inline-block;
	background-color: #e8e6ff;
	border: 1px solid #1e00ff;
	color: #1e00ff;
	padding: .1em .3em;
	margin: .1em .3em .1em 0;
	line-height: 1.5em;
}

.ant-input-search-button {
	height: 32px;
	margin-top: 10px;
	margin-left: 10px;
}

.site-tree-search-value {
	color: #f50;
}
[data-theme="dark"] .site-tree-search-value {
	color: #d84a1b;
}
