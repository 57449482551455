
.library{
    position: fixed;
    top: 70px;
    margin-left: 10px;
    width: 10%;
    border: 1px solid #959595;
    border-radius: .5em;
    padding: .1em .3em;
    overflow-y: auto;
    max-height: 70%;
}
